import { Grid } from '@progress/kendo-vue-grid';
import { filterBy, orderBy } from '@progress/kendo-data-query';
import { mediaQueryMixin } from '@/mixins/mediaQueryMixin.js';

function kgm_getNestedValue (fieldName, dataItem) {
  const path = fieldName.split('.');
  let data = dataItem;
  path.forEach((p) => {
    data = data ? data[p] : undefined;
  });

  return data;
}

export const kendoGridMixin = {
  mixins: [
    mediaQueryMixin
  ],
  data () {
    return {
      kgm_ref: 'gridReference',
      kgm_selectable: false,
      kgm_dataIndexes: null,
      kgm_storage: '',
      kgm_skip: 0,
      kgm_selectedField: "selectedField",
      kgm_pagable: {
        buttonCount: 5,
        info: true,
        type: 'numeric',
        pageSizes: [5, 10, 20, 50, 100],
        previousNext: true,
      },
      kgm_take: 10,
      kgm_filter: {
        logic: "and",
        filters: []
      },
      kgm_gridItems: [],
      kgm_sort: [],
      kgm_allowUnsort: true,
      kgm_selectedIndex: 1,
      kgm_sortMode: 'multiple'
    }
  },
  components: {
    Grid
  },
  created () {
    this.kgm_loadFromStorage();
  },
  mounted () {
    this.kgm_dataIndexes = new Set();
    if (this.kgm_selectable == false) {
      return;
    }
    document.addEventListener("keydown", this.kgm_onKeyPress);
  },
  beforeDestroy () {
    document.removeEventListener("keydown", this.kgm_onKeyPress);
    if (this.kgm_storage.length > 0) {
      this.kgm_saveToStorage();
    }
  },
  methods: {
    kgm_responsiveColumns (columnsObjectName) {
      let responsiveColumns = [];
      this[columnsObjectName || "kgm_columns"].forEach(column => {

        if (column.hideOn == null) {
          responsiveColumns.push(column);
          return;
        }

        let validationArray = [];
        column.hideOn.forEach(mediaQuery => {
          let isRightScreenSize = mediaQueryMixin.computed[mediaQuery](this);
          validationArray.push(isRightScreenSize);
        });
        if (validationArray.includes(true)) {
          return;
        }

        responsiveColumns.push(column);
      });

      return responsiveColumns;
    },
    kgm_openLinkInRow () {
      let linkInSelectedRow = document.querySelectorAll(`.k-state-selected > [dataindex="${this.kgm_selectedIndex}"] > a`)[0];
      if (linkInSelectedRow != null) {
        window.open(linkInSelectedRow.getAttribute("href"), "_self");
      }
    },
    kgm_selectDefaultOption (props, valueToCheck, selectReference) {
      if (!this.kgm_filter) {
        setTimeout(() => {
          this.$refs[selectReference].selectedIndex = 0;
        }, 1);
        return false;
      }
      if (!this.kgm_filter.filters.find(filter => filter.field === props.field)) {
        setTimeout(() => {
          this.$refs[selectReference].selectedIndex = 0;
        }, 1);
        return false;
      }
      if (this.kgm_filter.filters.find(filter => filter.field === props.field).value != valueToCheck) {
        return false;
      }
      return true;
    },
    kgm_allGridItems (items) {
      try {
        let itemsToUse = null;

        if (items != null && items.length > 0) {
          itemsToUse = filterBy(items, this.kgm_filter);
        } else {
          itemsToUse = [];
        }

        if (itemsToUse != null && itemsToUse.length > 0) {
          return itemsToUse ? itemsToUse.length : 0;
        } else {
          return 0;
        }
      } catch {
        //console.log("error: allGridItems");
      }
    },
    kgm_onlyUnique (value, index, self) { 
      return self.indexOf(value) === index;
    },
    kgm_resetSelect (selectReference, callback) {
      this.$refs[selectReference].selectedIndex = 0;
      callback();
    },
    kgm_resetInput (selectReference, callback) {
      this.$refs[selectReference].value = null;
      callback();
    },
    kgm_computedGridItems: function (items) {
      try {
        if (items == null || items.length == 0) {
          return [];
        }
        let filteredItems = filterBy(orderBy(items, this.kgm_sort), this.kgm_filter).slice(this.kgm_skip, this.kgm_take + this.kgm_skip);
        if (this.kgm_skip > this.kgm_allGridItems(items) && this.kgm_allGridItems(items) != 0) {
          this.kgm_skip = 0;
        }
        return filteredItems;
      } catch {
        //console.log("error: computedGridItems");
      }
    },
    kgm_computedAllGridItems: function (items) {
      try {
        if (items == null || items.length == 0) {
          return [];
        }
        let filteredItems = filterBy(orderBy(items, this.kgm_sort), this.kgm_filter);
        return filteredItems;
      } catch {
        //console.log("error: computedGridItems");
      }
    },
    kgm_onKeyPress (event) {
      if (event.target.classList[0] != "k-grid-table") {
        return;
      }
      let changed = false;
      // Arrow Down
      if (event.keyCode == 40) {
        this.kgm_selectedIndex++;
        changed = true;
      } else
      // Arrow Down
      if (event.keyCode == 38) {
        this.kgm_selectedIndex--;
        changed = true;
      } else
      // Page Up
      if (event.keyCode == 33) {
        this.kgm_skip -= this.kgm_take;
        this.kgm_selectedIndex -= this.kgm_take;
        changed = true;
      } else
      // Page Down
      if (event.keyCode == 34) {
        this.kgm_skip += this.kgm_take;
        this.kgm_selectedIndex += this.kgm_take;
        changed = true;
      } else
      // Enter
      if (event.keyCode == 13) {
        this.kgm_openLinkInRow();
      } else
      // Space
      if (event.keyCode == 32) {
        this.kgm_deselectRows();
        document.querySelectorAll('table.k-grid-table')[0].blur();
        this.kgm_selectedIndex = this.kgm_dataIndexes.values().next().value;
      }
      if (changed == true) {
        event.preventDefault();
        this.kgm_gridPageChange({ page: { skip: this.kgm_skip, take: this.kgm_take}});
      }
    },
    kgm_getDataIndexes () {
      let cells = document.querySelectorAll("td[dataindex]");
      this.kgm_dataIndexes.clear();
      for (let index = 0; index < cells.length; index++) {
        this.kgm_dataIndexes.add(Number(cells[index].getAttribute("dataindex")));
      }
      if (this.kgm_dataIndexes.has(this.kgm_selectedIndex) == false) {
        let shallowCopy = Array.from(this.kgm_dataIndexes);
        this.kgm_selectedIndex = this.kgm_findClosestValue(this.kgm_selectedIndex, shallowCopy);
      }
    },
    kgm_gridOnRowClick (event) {
      this.kgm_selectedIndex = Number(event.event.srcElement.parentNode.children[0].getAttribute("dataindex"));
      this.kgm_selectRow();
    },
    kgm_findClosestValue (value, array) {
      let distance = [];
      for (let index = 0; index < array.length; index++) {
        distance.push({ value: array[index] - value, index: index});
      }
      distance.sort((a, b) => {
        return a.value + b.value;
      });
      return array[distance[0].index];
    },
    kgm_deselectRows () {
      let selectedRow = document.querySelectorAll('tr.k-state-selected')[0];
      if (selectedRow != null) {
        selectedRow.classList.remove('k-state-selected');
      }
    },
    kgm_selectRow () {
      if (this.kgm_selectable == false) {
        return;
      }
      document.querySelectorAll('table.k-grid-table')[0].focus();
      this.kgm_getDataIndexes();
      this.kgm_deselectRows();
      let cellInRow = document.querySelectorAll(`[dataindex="${this.kgm_selectedIndex}"]`)[0];
      if (cellInRow != null) {
        let rowToSelect = cellInRow.parentElement;
        if (rowToSelect != null) {
          rowToSelect.classList.add('k-state-selected');
          let elementRect = rowToSelect.getBoundingClientRect();
          let absoluteElementTop = elementRect.top + window.pageYOffset;
          let middle = absoluteElementTop - (window.innerHeight / 2);
          window.scrollTo(0, middle);
        }
      }
    },
    kgm_gridSortChange (event) {
      this.kgm_sort = event.sort;
      this.kgm_saveToStorage();
    },
    kgm_gridPageChange (event) {
      this.kgm_selectedIndex += event.page.skip - this.kgm_skip;
      this.kgm_skip = event.page.skip;
      this.kgm_take = event.page.take;
      if (this.kgm_skip < 0) {
        this.kgm_skip = 0;
      }
      if ((this.kgm_take + this.kgm_skip) / this.kgm_take > this.$refs[this.kgm_ref].total / this.kgm_take) {
        this.kgm_skip = Math.floor(this.$refs[this.kgm_ref].total / this.kgm_take) * this.kgm_take;
      }
      this.kgm_saveToStorage();
      window.setTimeout(() => {
        this.kgm_selectRow();
      }, 1);
    },
    kgm_gridFilterChange (event) {
      this.kgm_filter = event.filter;
      this.kgm_saveToStorage();
    },
    kgm_saveToStorage () {
      if (this.kgm_storage && this.kgm_storage.length > 0) {
        localStorage[this.kgm_storage] = JSON.stringify({
          filter: this.kgm_filter,
          sort: this.kgm_sort,
          take: this.kgm_take,
          skip: this.kgm_skip
        });
      }
    },
    kgm_loadFromStorage () {
      if (this.kgm_storage && this.kgm_storage.length > 0) {
        let loadedStorage = localStorage[this.kgm_storage];
        if (!loadedStorage) {
          return;
        }
        let parsedStorage = JSON.parse(loadedStorage);
        this.kgm_filter = parsedStorage.filter;
        this.kgm_sort = parsedStorage.sort;
        this.kgm_take = parsedStorage.take;
        this.kgm_skip = parsedStorage.skip;
      }
    },
    // eslint-disable-next-line
    kgm_filterRender: function(h, defaultRendering, props, change, reset) {
      return defaultRendering;
    },
    kgm_getNestedValue: kgm_getNestedValue
  }
}