export const mediaQueryMixin = {
  data () {
    return {
      screenWidth: 0,
      // screenHeight: 0
    }
  },
  computed: {
    // EXTRA EXTRA SMALL SCREENS
    xxs (that) {
      if (that.screenWidth < 600) {
        return true;
      }
      return false;
    },
    xxsUp () {
      return true;
    },
    xxsDown (that) {
      if (that.screenWidth < 600) {
        return true;
      }
      return false;
    },
    // EXTRA SMALL SCREENS
    xs (that) {
      if (that.screenWidth >= 600 && that.screenWidth < 800) {
        return true;
      }
      return false;
    },
    xsUp (that) {
      if (that.screenWidth >= 600) {
        return true;
      }
      return false;
    },
    xsDown (that) {
      if (that.screenWidth < 800) {
        return true;
      }
      return false;
    },
    // SMALL SCREENS
    sm (that) {
      if (that.screenWidth >= 800 && that.screenWidth < 1000) {
        return true;
      }
      return false;
    },
    smUp (that) {
      if (that.screenWidth >= 800) {
        return true;
      }
      return false;
    },
    smDown (that) {
      if (that.screenWidth < 1000) {
        return true;
      }
      return false;
    },
    // MEDIUM SCREENS
    md (that) {
      if (that.screenWidth >= 1000 && that.screenWidth < 1200) {
        return true;
      }
      return false;
    },
    mdUp (that) {
      if (that.screenWidth >= 1000) {
        return true;
      }
      return false;
    },
    mdDown (that) {
      if (that.screenWidth < 1200) {
        return true;
      }
      return false;
    },
    // LARGE SCREENS
    lg (that) {
      if (that.screenWidth >= 1200 && that.screenWidth < 1400) {
        return true;
      }
      return false;
    },
    lgUp (that) {
      if (that.screenWidth >= 1200) {
        return true;
      }
      return false;
    },
    lgDown (that) {
      if (that.screenWidth < 1400) {
        return true;
      }
      return false;
    },
    // EXTRA LARGE SCREENS
    xl (that) {
      if (that.screenWidth >= 1400 && that.screenWidth < 1600) {
        return true;
      }
      return false;
    },
    xlUp (that) {
      if (that.screenWidth >= 1400) {
        return true;
      }
      return false;
    },
    xlDown (that) {
      if (that.screenWidth < 1600) {
        return true;
      }
      return false;
    },
    // EXTRA EXTRA LARGE SCREENS
    xxl (that) {
      if (that.screenWidth >= 1600) {
        return true;
      }
      return false;
    },
    xxlUp (that) {
      if (that.screenWidth >= 1600) {
        return true;
      }
      return false;
    },
    xxlDown () {
      return true;
    }
  },
  created () {
    this.onResize();
    window.addEventListener('resize', this.onResize);
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.onResize);
  },
  methods: {
    onResize () {
      this.screenWidth = window.innerWidth || document.body.clientWidth;
      // this.screenHeight = window.innerHeight || document.body.clientHeight;
    }
  }
}