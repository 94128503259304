<template>
  <div class="photopointList">
    <LoadingPlaceholder v-if="loading" />
    <template v-else>
      <Grid
        :ref="kgm_ref"
        :style="{height: 'auto'}"
        :data-items="kgm_computedGridItems(photopoints)"
        :columns="kgm_responsiveColumns()"
        :filterable="false"
        :filter="kgm_filter"
        :pageable="kgm_pagable"
        :page-size="kgm_take"
        :skip="kgm_skip"
        :take="kgm_take"
        :total="kgm_allGridItems(photopoints)"
        :sortable="{
          allowUnsort: true,
          mode: kgm_sortMode
        }"
        :sort="kgm_sort"
        selected-field="selected"
        @rowclick="kgm_gridOnRowClick"
        @filterchange="kgm_gridFilterChange"
        @pagechange="kgm_gridPageChange"
        @sortchange="kgm_gridSortChange"
      >
        <template
          slot="booleanTemplate"
          slot-scope="{props}"
        >
          <td :class="props.className">
            <input
              :checked="kgm_getNestedValue(props.field, props.dataItem)"
              type="checkbox"
              disabled
              readonly
            >
          </td>
        </template>
        <template
          slot="optionsTemplate"
          slot-scope="{props}"
        >
          <td :class="props.className">
            <div class="btn-group">
              <button
                class="btn btn-primary btn-sm"
                @click="showSidebarEdit(props.dataItem)"
              >
                <font-awesome-icon
                  class="mr-2"
                  icon="edit"
                />
                <span>{{ $t('edit') }}</span>
              </button>
            </div>
          </td>
        </template>
      </Grid>
      <Sidebar
        v-if="showSidebar"
        :show-sidebar="showSidebar"
        @close="close"
      >
        <PhotopointAdd
          v-if="addingElement"
          :resort-id="resortId"
          @reload="reloadPhotopoints"
        />
        <PhotopointEdit
          v-else
          :resort-id="resortId"
          :photopoint="photopoint"
          @reload="reloadPhotopoints"
        />
      </Sidebar>
    </template>
  </div>
</template>

<script>
import { kendoGridMixin } from '@/mixins/kendoGridMixin.js';
import PhotopointAdd from '@/components/Resort/PhotopointAdd';
import PhotopointEdit from '@/components/Resort/PhotopointEdit';
import Sidebar from '@/components/Base/Sidebar.vue';

export default {
  name: "PhotopointList",
  components: {
    Sidebar,
    PhotopointAdd,
    PhotopointEdit
  },
  mixins: [
    kendoGridMixin
  ],
  props: {
    resortId: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      loading: true,
      photopoints: null,
      photopoint: null,
      showSidebar: false,
      addingElement: false,
      kgm_columns: [
        {
          field: 'name',
          filterable: false,
          title: 'Name',
        },
        {
          field: 'type',
          filterable: false,
          filter: 'text',
          title: 'Type',
          hideOn: ['xsDown']
        },
        {
          field: 'displayName',
          filterable: false,
          title: 'Display Name',
          hideOn: ['smDown']
        },
        {
          field: 'svgTemplateName',
          filterable: false,
          title: 'SVG Template Name',
          hideOn: ['smDown']
        },
        {
          field: 'visible',
          filterable: false,
          title: 'Visible',
          cell: 'booleanTemplate'
        },
        {
          filterable: false,
          title: 'Options',
          cell: 'optionsTemplate',
        }
      ]
    }
  },
  watch: {
    resortId () {
      this.getData();
    }
  },
  created () {
    this.getData();
  },
  methods: {
    //sidebar
    show () {
      this.showSidebar = true;
    },
    close () {
      this.showSidebar = false;
    },
    showSidebarAdd () {
      this.addingElement = true;
      this.show();
    },
    showSidebarEdit (emitValues) {
      this.setCurrentPhotopoint(emitValues);
      this.addingElement = false;
      this.show();
    },
    setCurrentPhotopoint (photopoint) {
      this.photopoint = Object.assign({}, photopoint); 
    },
    reloadPhotopoints () {
      this.showSidebar = false;
      this.$emit("reloadAuditLogs");
      this.getData();
    },
    //#region API-calls
    getData () {
      this.loading = true;
      this.axios.get(`/Resort/GetResortPhotopoints?resortId=${ this.resortId }`)
      .then(response => {
        this.photopoints = response.data;
      })
      .finally(() => {
        this.loading = false;
      });
    }
  }
}
</script>
<style>
.photopointList .k-grid table tr:hover td {
  background :rgb(219, 219, 219) !important;
  cursor: pointer !important;
}
</style>