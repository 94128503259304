<template>
  <!--
    A container for content that can be slid in from the side of the screen.
    Usually used to display further information about something.
  -->
  <div
    :class="['sidebar-wrapper', { 'show': showSidebar === true }]"
    @mousedown.self="$emit('close')"
  >
    <div
      :style="`width: ${ sidebarWidth }px;`"
      class="sidebar"
    >
      <div class="closeSidebar">
        <button
          class="btn btn-secondary btn-sm"
          @click="$emit('close')"
        >
          <font-awesome-icon
            class="mr-2 gray"
            icon="times"
          />
          <span>{{ $t('close') }}</span>
        </button>
      </div>
      <div class="content">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import FreshdeskWidget from '../../plugins/FreshdeskWidget.js';

export default {
  name: 'Sidebar',
  props: {
    showSidebar: {
      type: Boolean,
      required: true
    },
    sidebarWidth: {
      type: Number,
      required: false,
      default: 400
    }
  },
  created () {
    FreshdeskWidget.hide();
  },
  beforeDestroy () {
    FreshdeskWidget.show();
  }
}
</script>

<style scoped>
.sidebar-wrapper {
  display: none;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0,0,0,0.25);
  z-index: 9999;
}
.sidebar-wrapper.show {
  display: initial;
}
.sidebar {
  background-color: white;
  box-shadow: 4px 3px 73px -2px rgba(0,0,0,.5);
  border-left:1px solid rgba(0,0,0,.25);
  position: fixed;
  right: 0;
  top: 0;
  /* width: 400px; */
  height: 100%;
  overflow-y: auto;
  max-width: 100%;
}
.sidebar .closeSidebar {
  position: absolute;
  left: 10px;
  top: 10px;
}
.sidebar .content {
  padding: 10px;
  padding-top: 50px;
  color: #656565;
}
</style>
