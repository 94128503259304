<template>
  <div class="photopointEdit">
    <form>
      <div class="form-group">
        <h4 class="m-0 mt-3">
          {{ 'Photopoint' }}
        </h4>
        <hr class="m-0 mb-3">
        <label>{{ $t('resortSpotAndPhotopoint.displayName') }}</label>
        <input
          v-model="photopoint.displayName"
          :class="['form-control', { 'is-invalid': $validator.errors.has('DisplayName') }]"
          type="text"
        >
        <hr class="m-0 mb-3">
        <label>{{ $t('visible') }}</label>
        <input
          v-model="photopoint.visible"
          type="checkbox"
          class="ml-3"
        >
      </div>
      <button
        class="btn btn-primary float-right"
        :disabled="!photopoint.displayName || !photopoint.name"
        @click.prevent="editPhotopoint()"
      >
        <font-awesome-icon
          class="mr-2"
          icon="edit"
        />{{ $t('edit') }}
      </button>
      <div class="clearfix" />
    </form>
  </div>
</template>

<script>
import { errorMixin } from '@/mixins/errorMixin.js';

export default {
  name: "PhotopointEdit",
  mixins: [
    errorMixin
  ],
  props: {
    resortId: {
      type: Number,
      required: true
    },
    photopoint: {
      type: Object,
      required: true
    }
  },
  methods: {
    async editPhotopoint () {
      await this.axios.post('/Resort/UpdatePhotopointForResort', this.photopoint)
        .then(() => {
          this.$snotify.success(this.$t('resortSpotAndPhotopoint.updatedSuccessfullyPhotopoint'));
          this.$emit("reload");
          this.error_clear();
        })
        .catch((error) => {
          this.error_clear();
          this.error_validate(error);
        });
    }
  }
}
</script>

<style scoped>
.photopointUpdate .progress {
  display: flex;
  flex: 1 1 auto;
  width: 1%;
  height: 20px;
  font-size: 1rem;
}
</style>